import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ScrollService {
  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private router: Router,
    private location: Location,
  ) {}

  public returnBack(): void {
    this.location.back();
  }

  public async triggerScrollTo(element: string): Promise<void> {
    if (this.router.url !== '/') {
      await this.router.navigateByUrl('/');
      this.scrollToElementWithRetry(element);
    } else {
      this.scrollToElementWithRetry(element);
    }
  }

  private scrollToElementWithRetry(
    elementId: string,
    maxRetries = 3,
    currentRetry = 0,
  ): void {
    setTimeout(() => {
      const targetElement = this.documentRef.getElementById(elementId);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else if (currentRetry < maxRetries) {
        this.scrollToElementWithRetry(elementId, maxRetries, currentRetry + 1);
      } else {
        console.error(
          `Failed to scroll to element: ${elementId} after ${maxRetries} attempts`,
        );
      }
    }, 150 * (currentRetry + 1));
  }
}
